import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar";
import Footer from "./Footer"; // New Footer component

const ProjectsPage = () => {
  // Sample blog post data
  const blogPosts = [
    {
      date: "11/02/24",
      title: "Expanding Your Social Circle",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/02/24",
      title: "Becoming a Better Software Developer",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/02/24",
      title: "A Step-by-Step Guide to Building Projects",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/02/24",
      title: "Building Connections: Strategies for Developers",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/02/24",
      title: "Enhancing Your Skills as a Software Developer",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/03/24",
      title: "Creating a Blockchain from Scratch",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/12/24",
      title: "Strengthening Friendships in Development",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/05/24",
      title: "Mastering the Art of Software Development",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/02/24",
      title: "Blockchain Development: A Complete Guide",
      type: "Blog",
      link: "#",
    },
    {
      date: "11/02/24",
      title: "Blockchain Development: A Complete Guide",
      type: "Blog",
      link: "#",
    },
  ];

  return (
    <Container>
      <NavBar />
      <MainContent>
        <LeftColumn>
          <Title>CONTINUE BUILDING</Title>
          <ProjectImage src="builder.png" alt="Builder Image" />
        </LeftColumn>
        <RightColumn>
          <ScrollableContainer>
            <BlogList>
              {blogPosts.map((post, index) => (
                <BlogListItem key={index}>
                  <a href={post.link}>
                    <strong>{post.date} - </strong>
                    <span>{post.title} - </span>
                    <em>{post.type}</em>
                  </a>
                </BlogListItem>
              ))}
            </BlogList>
          </ScrollableContainer>
        </RightColumn>
      </MainContent>
      <Footer /> {/* Add the Footer here */}
    </Container>
  );
};

export default ProjectsPage;

// Styled components
const Container = styled.div`
  font-family: "Arial", sans-serif;
  color: #2a2a2a;
  text-align: center;
  padding: 0 1rem;
  width: 100%;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem 5%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  max-width: 40%;
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const RightColumn = styled.div`
  max-width: 55%;
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: clamp(4rem, 6vw, 6rem); /* Moderate title size */
  font-weight: 900;
  font-family: "Alegreya Sans SC", sans-serif;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.1;
  margin-bottom: 1rem; /* Add margin to avoid overlap */

  @media (max-width: 768px) {
    font-size: clamp(3rem, 5vw, 5rem); /* Adjust title for smaller screens */
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  max-width: 400px; /* Adjust image size */
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    max-width: 80%; /* Adjust image size on smaller screens */
  }
`;

const ScrollableContainer = styled.div`
  max-height: 50vh; /* Limit the height of the container */
  overflow-y: scroll; /* Enable vertical scrolling */
  padding-right: 1rem;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }

  /* For Firefox */
  scrollbar-width: none;
`;

const BlogList = styled.ul`
  padding-left: 0; /* Remove default padding */
`;

const BlogListItem = styled.li`
  font-size: 1.4rem; /* Larger list item text */
  line-height: 1.8;
  list-style-type: none; /* Remove bullet points */
  margin-bottom: 0.5rem;

  a {
    text-decoration: none;
    color: #2a2a2a;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate overflowing text */
  }

  a:hover {
    color: #1da1f2; /* Change color on hover */
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
