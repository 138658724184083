import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar"; // Import the NavBar component
import { FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";


const AboutPage = () => {
  return (
    <Container>
      {/* Navigation */}
      <NavBar />

      {/* Main Section */}
      <MainSection>
        <TreeImage src="tree.png" alt="Tree Image" />
        <TextContent>
          <MainHeading>EDGER TO</MainHeading>
          <MainHeading>CONTINUE BUILDING</MainHeading>
          <MainHeading>NEW CONNECTIONS</MainHeading>
          <Description>
            I specialize in software development, focusing on the deployment,
            implementation, and maintenance of web and low-level applications.
            My aim is to optimize performance and scalability, delivering
            efficient solutions in both startup environments and large-scale
            multinational tech companies.
          </Description>
        </TextContent>
      </MainSection>

      {/* Footer */}
      <Footer>
        <SocialIcons>
          <SocialLink
            href="https://x.com/andres_vanml"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </SocialLink>
          <SocialLink
            href="https://github.com/andresvanegas19"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/in/santiago-reyes/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </SocialLink>
        </SocialIcons>
      </Footer>
    </Container>
  );
};

export default AboutPage;

// Styled components
const Container = styled.div`
  font-family: "Arial", sans-serif;
  color: #2a2a2a;
  text-align: center;
  overflow-x: hidden; /* Ensure no horizontal overflow */
  width: 100%; /* Ensure it takes the full width */
`;

const MainSection = styled.div`
  display: flex;
  justify-content: center; /* Center the content */
  align-items: flex-start;
  padding: 2rem 5%; /* Add some padding but keep it tight */
  gap: 2rem; /* Smaller gap between tree and text */
  flex-wrap: wrap; /* Wrap content on smaller screens */
`;

const TreeImage = styled.img`
  width: 30vw; /* Adjust to fit within viewport */
  max-width: 400px; /* Ensure image doesn't exceed max width */
  flex-shrink: 0;
`;

const TextContent = styled.div`
  max-width: 600px; /* Keep text from overflowing */
  width: 100%; /* Make sure text takes up available space */
  text-align: left;
  flex-grow: 1; /* Ensure text can expand and fill space */

  @media (max-width: 768px) {
    text-align: center; /* Center text on smaller screens */
    padding-left: 1rem; /* Padding for smaller screens */
    padding-right: 1rem;
  }
`;

const MainHeading = styled.h1`
  font-size: clamp(2.5rem, 5vw, 5rem); /* Dynamically adjust heading size */
  font-weight: 900;
  font-family: "Alegreya Sans SC", sans-serif;
  text-transform: uppercase;
  line-height: 1.1;
  margin: 0;
  word-break: keep-all; /* Avoid breaking words awkwardly */
  text-align: left;

  @media (max-width: 768px) {
    text-align: center; /* Center headings on small screens */
  }
`;

const Description = styled.p`
  font-size: clamp(1rem, 2vw, 1.5rem); /* Adjust paragraph size dynamically */
  margin-top: 1.5rem;
  line-height: 1.6;
`;

// Footer Section
const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  width: 100%;
  position: fixed; /* Fix footer to the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 2rem; /* Space between social media icons */
  font-size: 2rem; /* Adjust icon size */
`;

const SocialLink = styled.a`
  color: #2a2a2a;
  transition: color 0.3s ease;

  &:hover {
    color: #1da1f2; /* Light blue color on hover (for Twitter-like style) */
  }
`;
